import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useDocsList() {
  // Use toast
  const toast = useToast()

  const refDocsListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: 'id', sortable: true },
    { key: 'titulo', label: 'Titulo', sortable: true },
    { key: 'fk_user', label: 'Creado por', sortable: false },
    { key: 'file_name', label: 'Fichero', sortable: true },
    { key: 'created_at', label: 'Fecha', sortable: true },
    { key: 'actions', label: '', thStyle: { minWidth: "120px" } },
  ]
  const perPage = ref(50)
  const totalDocs = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [50, 100, 200, 500]
  const searchQuery = ref('')
  const sortBy = ref('created_at')
  const isSortDirDesc = ref('')
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refDocsListTable.value ? refDocsListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalDocs.value,
    }
  })

  const refetchData = () => {
    refDocsListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  })

  const fetchDocs = (ctx, callback) => {
    store
      .dispatch('app-docs/fetchDocs', {
        body: {
          search: searchQuery.value,
          paginate: perPage.value,
        },
        headers: {
          'X-Sort': `docs_generados.${sortBy.value}`,
          'X-Sort-Order': isSortDirDesc.value ? 'asc' : 'desc',
        },
        query: {
          paginate: true,
          page: currentPage.value,
        },
      })
      .then(res => {
        const { total } = res
        totalDocs.value = total

        callback(res.data)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching docs list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const parseDate = date => {
    let d = date.substring(0, 10)
    d = d.split('-').reverse().join('-')
    const t = date.substring(12, 19)
    return `${d} ${t}`
  }

  return {
    fetchDocs,
    tableColumns,
    perPage,
    currentPage,
    totalDocs,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refDocsListTable,

    refetchData,
    parseDate,
  }
}
