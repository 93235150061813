<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrar</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entradas</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refDocsListTable"
        class="position-relative"
        :items="fetchDocs"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No se encontraron registros coincidentes"
        :sort-desc.sync="isSortDirDesc"
        :busy="!fetchDocs"
      >
        <!-- Table Loading -->
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner
              class="align-middle"
              small
            />
            <strong class="ml-1">Cargando datos...</strong>
          </div>
        </template>

        <!-- Column: Id -->
        <template #cell(id)="data">
          #{{ data.item.id }}
        </template>

        <!-- Column: FK User -->
        <template #cell(fk_user)="data">
          #{{ data.item.fk_user }}
        </template>

        <!-- Column: Fecha -->
        <template #cell(created_at)="data">
          {{ formatDate(data.item.created_at) }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-row class="actions"  >
            <b-button
              :id="`edit-${data.item.id}`"
              @click="openPdf(data.item)"
              variant="primary"
              class="btn-icon"
            >
              <feather-icon icon="PrinterIcon" />
            </b-button>
            <b-tooltip
              :target="`edit-${data.item.id}`"
              triggers="hover"
              placement="bottom"
              boundary="window"
            >
              <b>Imprimir PDF</b>
            </b-tooltip>
            <b-button
              :id="`delete-${data.item.id}`"
              @click="deleteModal = true; selectedItem = data.item"
              variant="danger"
              class="btn-icon"
            >
              <feather-icon icon="TrashIcon" />
              <b-tooltip
                :target="`delete-${data.item.id}`"
                triggers="hover"
                placement="bottom"
                boundary="window"
              >
                <b>Eliminar Documento</b>
              </b-tooltip>
            </b-button>
          </b-row>

          <!-- <print-doc
            @close="printModal = false"
            :show="printModal"
            :doc="data.item"
          /> -->

          <ConfirmationModal
            :id="`confirmation-${selectedItem.id}`"
            :show="deleteModal"
            message="Esta acción no se puede revertir y se perderá el doc."
            title="¿Está seguro de que desea eliminar el doc?"
            @action="deleteDoc(selectedItem.id)"
            @close="deleteModal = false"
          />
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Mostrando {{ dataMeta.from }} a {{ dataMeta.to }} de
              {{ dataMeta.of }} entradas</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalDocs"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BLink,
  BButton,
  BFormInput,
  BTable,
  BPagination,
  BSpinner,
  BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import moment from 'moment'
import { PDF_PUBLIC_PATH } from '@/api/v1'
import ConfirmationModal from '@/components/ConfirmationModal.vue'
import useDocsList from './useDocsList'
import docsStoreModule from './docsStoreModule'

// import PrintDoc from "@/views/deseno/docs/PrintDoc.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BLink,
    BButton,
    BFormInput,
    BTable,
    BPagination,
    BSpinner,
    BTooltip,
    vSelect,
    ConfirmationModal,
    // PrintDoc,
  },
  data() {
    return {
      deleteModal: false,
      // printModal: false,
      selectedItem: {},
    }
  },
  methods: {
    deleteDoc(id) {
      this.$store.dispatch('app-docs/deleteDoc', id).then(() => {
        this.deleteModal = false
        this.refetchData()
      })
    },
    formatDate(dateString) {
      moment.locale('es')
      return moment(dateString).format('DD-MM-YYYY H:mm')
    },
    openPdf(item) {
      window.open(`${PDF_PUBLIC_PATH}/${item.file_name}`)
    },
  },
  setup() {
    const DOCS_APP_STORE_MODULE_NAME = 'app-docs'

    // Register module
    if (!store.hasModule(DOCS_APP_STORE_MODULE_NAME)) store.registerModule(DOCS_APP_STORE_MODULE_NAME, docsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DOCS_APP_STORE_MODULE_NAME)) store.unregisterModule(DOCS_APP_STORE_MODULE_NAME)
    })

    const {
      fetchDocs,
      tableColumns,
      perPage,
      currentPage,
      totalDocs,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDocsListTable,
      refetchData,

      // UI
      parseDate,
    } = useDocsList()

    return {
      fetchDocs,
      tableColumns,
      perPage,
      currentPage,
      totalDocs,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDocsListTable,
      refetchData,

      // UI
      parseDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style>
.actions .btn-icon {
  margin: 0 3px;
}
</style>
